import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from '../../shared/services/message.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import * as $ from 'jquery';
import 'rxjs/add/operator/map';
declare var includeBelegzeitenkalender: any;
import 'gsap';

@Component({
  selector: 'apartments-detail',
  templateUrl: './apartments-detail.component.html'
})


export class ApartmentsDetailComponent implements OnInit {

  @Output() onFilter: EventEmitter<any> = new EventEmitter();

  data:any = null;
  loaded:any = null;
  dataCommon:any = null;
  loadedCommon:any = null;
  dataSeason:any = null;
  loadedSeason:any = null;
  private currentRoute:String = null;

  constructor(private _http: Http, private route:ActivatedRoute, private _messageService: MessageService, public sanitizer: DomSanitizer) {
    this.currentRoute = route.snapshot.routeConfig.path;
  }

  ngOnInit(){
    this.getWPData();
  }

  createRange(i: number) {
    return new Array(i);
  }

  private getWPData() {

    var self = this;
    var id:any = null;

    if(this.currentRoute == 'rungholt') {
      id = 5;
    } else if(this.currentRoute == 'jordsand') {
      id = 7;
    } else if(this.currentRoute == 'ruem-hart') {
      id = 204;
    } else if(this.currentRoute == 'min-eilun') {
      id = 206;
    }

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/'+id)
      .map((res: Response) => res.json())
      .subscribe(data => {
        this._messageService.filter(data);
        this.data = data;
        this.loaded = true;
      });

    this._http.get('https://haussylta.de/wp-json/acf/v3/pages/172')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.dataCommon = data;
        this.loadedCommon = true;
      });

    this._http.get('https://haussylta.de/wp-json/acf/v3/options/options')
      .map((res: Response) => res.json())
      .subscribe(data => {
        this.dataSeason = data;
        this.loadedSeason = true;
      });

    var asyncTimer = setInterval(function(){ dataLoaded() }, 10);

    function dataLoaded() {
      if(self.loaded == true && self.loadedCommon == true && self.loadedSeason == true) {
        clearInterval(asyncTimer);
        self.domReady();
      }
    }
  }

  private getSwiper(_index) {
    $('.m-air').toggleClass('fullscreen');
    this.resizer('air',null);
  }

  private domReady () {
    var self = this;

    setTimeout(function(){
      $('.js-calendar .js-load-text').addClass('hide');
      $('.js-calendar iframe').addClass('show');
    }, 2000);

    $('.js-expand').find('div:nth-child(1)').each(function() {

      var $panel = $(this).next();
      $panel.css('max-height', $panel[0].scrollHeight+'px')

      $(this).on('click', function() {
        $(this).toggleClass('active');
        $panel.toggleClass('active');
        self.resizer($(this),$panel);
      });
    });
  }

  /**
   * resizer
   * recalculate content height
   */
  private resizer (headline,content) {

    if(headline !== 'air') {
      if (headline.hasClass('active')){
        content.css('max-height', '0')
      } else {
        content.css('max-height', content[0].scrollHeight+'px')
      }
    }

    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;

    if($('.m-air').hasClass('fullscreen')) {
      $('.m-air').find('img').css('max-width',windowWidth+'px');
      $('.m-air').find('img').css('max-height',windowHeight+'px');
      $('.m-air').css('width',windowWidth+'px');
      $('.m-air').css('height',windowHeight+'px');
    } else {
      $('.m-air').find('img').css('max-width','100%');
      $('.m-air').find('img').css('max-height','100%');
      $('.m-air').css('width','100%');
      $('.m-air').css('height','auto');
    }
  }
}
