import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { ApartmentsOverviewComponent } from './pages/apartments-overview/apartments-overview.component';
import { ApartmentsDetailComponent } from './pages/apartments-detail/apartments-detail.component';
import { CalendarComponent } from './pages/calendar/calendar.component';
import { HistoryComponent } from './pages/history/history.component';
import { ValuationsComponent } from './pages/valuations/valuations.component';
import { ContactComponent } from './pages/contact/contact.component';
import { LegalComponent } from './pages/legal/legal.component';

const routes: Routes = [
  { path: 'home', component: HomeComponent, data: { animation: 'home' } },
  { path: 'wohnungen', component: ApartmentsOverviewComponent, data: { animation: 'apartments' } },
  { path: 'rungholt', component: ApartmentsDetailComponent, data: { animation: 'rungholt' } },
  { path: 'jordsand', component: ApartmentsDetailComponent, data: { animation: 'jordsand' } },
  { path: 'min-eilun', component: ApartmentsDetailComponent, data: { animation: 'min-eilun' } },
  { path: 'ruem-hart', component: ApartmentsDetailComponent, data: { animation: 'ruem-hart' } },
  { path: 'kalender', component: CalendarComponent, data: { animation: 'kalender' } },
  { path: 'kalender-rungholt', component: CalendarComponent, data: { animation: 'kalender-rungholt' } },
  { path: 'kalender-jordsand', component: CalendarComponent, data: { animation: 'kalender-jordsand' } },
  { path: 'kalender-min-eilun', component: CalendarComponent, data: { animation: 'kalender-min-eilun' } },
  { path: 'kalender-ruem-hart', component: CalendarComponent, data: { animation: 'kalender-ruem-hart' } },
  { path: 'historie', component: HistoryComponent, data: { animation: 'historie' } },
  { path: 'gaestebuch', component: ValuationsComponent, data: { animation: 'gaestebuch' } },
  { path: 'kontakt', component: ContactComponent, data: { animation: 'kontakt' } },
  { path: 'impressum', component: LegalComponent, data: { animation: 'impressum' } },
  { path: 'datenschutz', component: LegalComponent, data: { animation: 'datenschutz' } },
  { path: '**', redirectTo: '/home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {}
